import ROUTE_NAME from 'apps/olive/src/utils/url-constants';
import logoiWarranty from '../../../assets/images/logo/logo-iwarranty-white.svg';

/* eslint-disable-next-line */
export interface FooterProps {}

export function Footer(props: FooterProps) {
  return (
    <footer className="hidden lg:block bg-[#171B26] text-sm text-[#EBEBEB] text-left md:text-center px-2 pt-8 pb-24 md:py-8">
      <div className="flex flex-col gap-2">
        <div className="flex justify-start md:justify-center">
          <img src={logoiWarranty} alt="iWarranty" width={'110px'}/>
        </div>
        <div>Copyright © 2023 iWarranty. All rights reserved. </div>
        <div className="flex gap-2 justify-start md:justify-center">
          <a href={ROUTE_NAME.TERMS_AND_CONDITIONS} target='_blank' className='text-[#EBEBEB]'>Terms and Conditions</a>
          <a href={ROUTE_NAME.PRIVACY_POLICY} target='_blank' className='text-[#EBEBEB]'>Privacy Policy</a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
