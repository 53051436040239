import { Shelta_RegisterHolding } from '@rabbit/bizproc/client';
import { useMyConsumerPersona } from '@rabbit/data/portal';
import { OliveHoldingRegistrationFormData } from '@rabbit/elements/shared-types';
import { useOliveAPI } from '../api/useOliveAPI';

// NEED TO BE MOVED TO THE SERVER AND MADE TENANT INDEPENDENT - DC
export function useCreateHoldingFromVendable(vendableDocid: string) {
  const { registerSheltaHolding } = useOliveAPI();

  // Get the consumer persona
  const consumerPersona = useMyConsumerPersona();
  if (!consumerPersona.data.consumer_private) {
    throw new Error('Consumer persona not found');
  }

  async function createUserHolding(formData: OliveHoldingRegistrationFormData) {
    if (!vendableDocid) {
      throw new Error('Vendable docid is required');
    }

    const holdingId = await registerSheltaHolding({ vendableDocid, formData });
    return holdingId;
  }

  return createUserHolding;
}
