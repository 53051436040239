import {
  LIST_COUNTRIES,
  useCreateHoldingFromVendable,
} from '@rabbit/bizproc/react';
import { Input, Button, Heading } from '@rabbit/elements/shared-components';
import { useAppInfo } from 'apps/sage/src/utils/helpers';
import { Formik, Form } from 'formik';
import { Dispatch, SetStateAction, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

//@ts-ignore
const isDemoEnv = import.meta.env.VITE_DEMO;

interface FormValuesShape {
  purchase_time: number;
  purchase_price: {
    amount: number;
    currency: string;
  };
  purchase_location: {
    docid: string;
    country: string;
  } | null;
  store_not_listed: boolean;
  custom_store_name: string;
  product_country: string;
  serial_number: string;
  purchase_country: string;
}

// TODO: NEED TO KNOW WHAT FIELDS ARE REQUIRED
const validationSchema = Yup.object().shape({
  purchase_location: Yup.mixed(),
  store_not_listed: Yup.boolean(),
  purchase_price: Yup.object({
    amount: Yup.number().min(1).required('Please enter a valid amount'),
    currency: Yup.string().trim().required('Currency is required'),
  }).required('Please enter a purchase price'),
  purchase_time: Yup.date()
    .max(new Date(), 'Please enter a valid date')
    .required('Please enter a valid date'),
  serial_number: isDemoEnv
    ? Yup.string().trim().min(13).required('Please enter a serial number')
    : Yup.string().trim(),
  purchase_country: Yup.string(),
});

interface NLICRegisterHoldingProps {
  vendableId: string;
  onChangeStep: (data: any) => void;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
}

function NLICRegisterHolding({
  vendableId,
  onChangeStep,
  setIsLoading,
}: NLICRegisterHoldingProps) {
  // Data should come from props but this serves as a fallback in case something goes wrong. Might remove this or the prop later
  const appInfo = useAppInfo();
  const NLIC_VendableId = window.localStorage.getItem('NLIC_VendableId');
  const [isSubmiting, setSubmiting] = useState(false);

  const createUserHolding = useCreateHoldingFromVendable(
    vendableId ?? NLIC_VendableId ?? ''
  );

  if (!vendableId && !NLIC_VendableId) <Navigate to={'/'} />;

  const initialValues: FormValuesShape = {
    purchase_time: 0,
    purchase_price: {
      amount: 0,
      currency: appInfo.currency,
    },
    purchase_location: null,
    store_not_listed: false,
    custom_store_name: '',
    product_country: '',
    serial_number: '',
    purchase_country: '',
  };

  const onSubmit = async (values: FormValuesShape) => {
    setSubmiting(true);
    setIsLoading(true);

    // If values include a retailer from the database, then purchase_location_other should be cleared - and vice versa
    const newPurchaseLocation =
      values.custom_store_name && values.store_not_listed
        ? null
        : values.purchase_location?.docid ?? '';

    const newPurchaseLocationOther = values.store_not_listed
      ? values.custom_store_name ?? ''
      : null;

    const data = {
      purchase_location: newPurchaseLocation,
      purchase_location_other: newPurchaseLocationOther,
      purchase_price: values.purchase_price,
      purchase_time: values.purchase_time,
      serial: values?.serial_number ?? '',
      purchase_country: values.purchase_location
        ? values.purchase_location.country
        : values.product_country,
    };

    try {
      const res = await createUserHolding(data);
      window.localStorage.removeItem('NLIC_VendableId');
      onChangeStep(res);
    } catch (err) {
      toast.error('Something went wrong, please try again.');
      setSubmiting(false);
      setIsLoading(false);
      console.log(err);
    }
  };

  return (
    <div className="mt-8">
      <div className="mb-3">
        <Heading kind={'h3'}>When and where did you buy your product?</Heading>
      </div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ errors, values }) => (
          <Form className="font-nunito flex flex-col gap-4">
            <Input
              type="datepicker"
              name="purchase_time"
              settings={{ placeholder: 'Purchase date', maxDate: new Date() }}
            />
            <Input
              type="currency"
              name="purchase_price"
              settings={{
                id: 'purchase_price',
                placeholder: 'Purchase amount',
                currency: values?.purchase_price?.currency ?? appInfo.currency,
                hint: '*required',
              }}
            />
            <Input
              type="autocomplete-location-retailer"
              name="purchase_location"
              settings={{
                placeholder: 'Enter store name & location',
                isMulti: false,
                disabled: values.store_not_listed,
                options: [],
              }}
            />
            <Input
              type="checkbox"
              name="store_not_listed"
              settings={{
                checkboxLabel: 'Store is not listed',
                checkboxLabelStyles: 'text-base text-gray-500',
              }}
            />
            {values.store_not_listed && (
              <>
                <Input
                  type="text"
                  name="custom_store_name"
                  settings={{
                    id: 'custom_store_name',
                    placeholder: 'Enter store name',
                  }}
                />
                <Input
                  type="select"
                  label=""
                  name="purchase_country"
                  settings={{
                    options: LIST_COUNTRIES,
                    id: 'purchase_country',
                    placeholder: 'The country where the product was purchased',
                  }}
                />
              </>
            )}
            {isDemoEnv ? (
              <Input
                type="text"
                name="serial_number"
                settings={{
                  placeholder: 'Serial number',
                }}
              />
            ) : null}
            <Button
              kind="primary"
              type="submit"
              disabled={
                Object.keys(errors).length > 0 ||
                values?.purchase_time === 0 ||
                !(values.purchase_location || values.purchase_country) ||
                isSubmiting
              }
              loading={isSubmiting}
            >
              Continue
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default NLICRegisterHolding;
