import { Dispatch, SetStateAction, useState } from 'react';
import { Button, Heading } from '@rabbit/elements/shared-components';
import { OliveHoldingRegistrationFormData } from '@rabbit/elements/shared-types';
import { DTVendable } from '@rabbit/data/types';
import { useConsumerHoldingEditor } from '@rabbit/bizproc/react';
import { useMyConsumerPersona } from '@rabbit/data/portal';
import { useNavigate } from 'react-router-dom';
import ROUTE_NAME from '../../utils/url-constants';
import { OliveFileUploader } from '@rabbit/olive/components/organisms/upload-wrapper/OliveFileUploader';
import { UploadedFileCategories } from '@rabbit/elements/shared-types';

interface RegisterHoldingProofStepProps {
  setProductRegistrationData: React.Dispatch<OliveHoldingRegistrationFormData>;
  createUserHolding: (data: OliveHoldingRegistrationFormData) => void;
  productRegistrationData: OliveHoldingRegistrationFormData;
  product?: DTVendable;
  holdingId: string;
  onChange: (value: number, holdingId: string) => void;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
}

export function RegisterHoldingProofStep({
  onChange,
  holdingId,
  setIsLoading,
}: RegisterHoldingProofStepProps) {
  const navigate = useNavigate();
  const persona = useMyConsumerPersona();
  const { body } = useConsumerHoldingEditor(
    persona?.data?.consumer_private?.docid ?? '',
    holdingId
  );

  const [isSubmitting, setIsSubmitting] = useState(false);

  // Once you get here, you have at least one holding as it was created during the previous step
  const userHasHoldings =
    persona?.data?.consumer_private?.holdings &&
    persona?.data?.consumer_private?.holdings?.length > 1;

  const onSubmit = () => {
    setIsSubmitting(true);
    setIsLoading(true);
    //TODO TemporaryFixRemovedQuestionnaire
    //onChange(3, holdingId);
    navigate(
      `${ROUTE_NAME.PRODUCTS}/${body?.public?.docid}/details${
        !userHasHoldings ? '?new-add-success' : ''
      }`
    );
  };

  return (
    <div className="wrapper">
      <div className="flex flex-col gap-4">
        <Heading kind={'h3'}>Upload your receipt</Heading>
        <Heading kind={'p'}>
          We will store your proof of purchase for you, so if you need it in the
          future you can access it in iWarranty.
        </Heading>
        <div className="flex flex-col">
          <div className="font-nunito flex flex-col gap-5">
            <OliveFileUploader
              label="Proof of purchase"
              identifiers={{
                category: UploadedFileCategories.ConsumerProofPurchase,
                docType: { docid: holdingId, type: 'holding' },
                personaId: persona?.personaId ?? '',
              }}
              shouldAutoUpdateDocs={true}
              currentFiles={[]}
              accepts={['image/*', '.pdf']}
            />
            <Button
              kind="primary"
              disabled={body?.private?.receipt?.length === 0 || isSubmitting}
              loading={isSubmitting}
              onClick={onSubmit}
            >
              Submit
            </Button>
            {body?.private?.receipt?.length === 0 ? (
              <Button
                kind="outline"
                //TODO TemporaryFixRemovedQuestionnaire
                // onClick={
                //   () => onChange(3, holdingId)
                // }
                onClick={() =>
                  navigate(
                    `${ROUTE_NAME.PRODUCTS}/${body?.public?.docid}/details${
                      !userHasHoldings ? '?new-add-success' : ''
                    }`
                  )
                }
              >
                I'll do it later
              </Button>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}

export default RegisterHoldingProofStep;
