import { CFC_RegisterSheltaHolding } from '@rabbit/bizproc/core';
import {
  APICallParamsBase,
  APICallResultBase,
  Shelta_RegisterHoldingParams,
  Shelta_RegisterHoldingResult,
} from '@rabbit/elements/shared-types';
import { z } from 'zod';

export async function Shelta_RegisterHolding({
  requestId,
  apiVersion,
  vendableDocid,
  formData,
}: Shelta_RegisterHoldingParams & APICallParamsBase): Promise<
  Shelta_RegisterHoldingResult & APICallResultBase
> {
  const result = await CFC_RegisterSheltaHolding.call({
    requestId,
    apiVersion,
    vendableDocid,
    formData,
  });
  if (!result.ok) throw new Error(result.error);
  // TODO: [DEV-511]
  return result as Shelta_RegisterHoldingResult & APICallResultBase;
}
