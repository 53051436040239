import {
  DocumentCheckIcon,
  InformationCircleIcon,
} from '@heroicons/react/24/outline';
import {
  CaseFlowCaseEditingID,
  STATIONS,
  useCaseFlowCase,
} from '@rabbit/bizproc/react';
import { TableCover } from '@rabbit/data/types';
import {
  formatUnixTime,
  getStationByKey,
  LoadingSpinner,
  ProgressBar,
  StepsProgress,
} from '@rabbit/elements/shared-components';
import classNames from 'classnames';
import carImagePlaceholder from '../../../assets/images/car-placeholder.jpg';

export interface CardRepairProps {
  id: CaseFlowCaseEditingID;
  onClick?: () => void;
  showProgressDetails?: boolean;
  showSpotlight?: boolean;
  getStoneImages?: (arg: string) => string | undefined;
}

const dynamicStyles = {
  container:
    'flex-grow w-full rounded-md bg-gray-50 inline-flex flex-col items-start pr-4 p-6',
  wrapper: 'flex flex-col items-start font-nunito flex-1',
  title: 'font-bold text-base leading-4 lg:leading-5  text-ellipsis',
  subtitle: 'font-normal text-sm pt-[5px] leading-2 capitalize',
  text: 'font-light text-sm pt-1.5 leading-3 md:leading-4',
};

export function CardRepair({
  id,
  onClick,
  showProgressDetails = false,
  showSpotlight,
  getStoneImages,
}: CardRepairProps) {
  const { caseFlowCase, isLoading } = useCaseFlowCase(id);

  if (isLoading || !caseFlowCase) return <LoadingSpinner size="xs" />;

  const data = caseFlowCase?.GetAllFacts();
  const caseState = caseFlowCase?.GetCaseState();
  const caseSpotlight = caseFlowCase?.GetCaseSpotlight();
  const hasSpotlight =
    caseSpotlight && caseSpotlight?.length > 0 && caseSpotlight[0][0] === 'C';
  const chatLog = caseFlowCase?.GetAllChat();
  const [lastMessage] = chatLog?.slice(-1) ?? [];
  const consumerChatLastSeen = caseFlowCase?.GetMyChatLastSeen() ?? 0;
  const hasChatUpdate = lastMessage?.t > consumerChatLastSeen;

  const currentStation = getStationByKey(caseState ?? ('' as string));
  const progressBarWidth = currentStation?.id
    ? ((currentStation.id / (STATIONS.length - 1)) * 100).toString()
    : '0';

  const productImage = data?.srvProductInfo?.srvInfo?.productInfo
    ? getStoneImages &&
      getStoneImages(
        (data?.srvProductInfo?.srvInfo?.productInfo as TableCover).typeofStone
      )
    : undefined;
  const actors = caseFlowCase.GetActors();
  const holdingBrand = data?.srvProductInfo?.brand || '';
  const holdingName = data?.consumer_holding_name || '';
  // Concatenate brand and name with a space in between
  const fullProductName = `${holdingBrand} ${holdingName}`.trim();

  const allStates: {
    oldState: string;
    newState: string;
    t: number;
  }[] = caseFlowCase.GetAllStates();
  const validState = (state: { newState: string }) => {
    const stateStation = STATIONS.find(
      (station) => station.key === state.newState
    );
    return stateStation && stateStation.id <= currentStation.id;
  };

  // Get the latest station for status
  const getLatestStatusStation = (status: (typeof STATIONS)[0]['status']) => {
    const statusStations = STATIONS.filter(
      (station) => station.status === status
    );
    const states = allStates.filter((state) =>
      statusStations.find((station) => station.key === state.newState)
    );
    return states.length > 0 ? states[states.length - 1] : undefined;
  };

  const assessmentStatusStation = getLatestStatusStation('Assessment');
  const waitingStatusStation = getLatestStatusStation('Waiting for review');
  const repairStatusStation = getLatestStatusStation('Repair/Replace');
  const completeStatusStation = getLatestStatusStation('Complete');

  const steps = [
    {
      Icon: InformationCircleIcon,
      completed: currentStation.id >= 1 ? true : false,
      title: 'Waiting for Review',
      content: waitingStatusStation && validState(waitingStatusStation) && (
        <div className="mt-2 text-xs text-gray-400 font-nunito">
          {formatUnixTime(waitingStatusStation.t, 'dd/MM/yyyy')}
          <br />
          {formatUnixTime(waitingStatusStation.t, 'hh:mm a')}
        </div>
      ),
    },
    {
      Icon: DocumentCheckIcon,
      completed: currentStation.id >= 2 ? true : false,
      title: 'Assessment',
      content: assessmentStatusStation &&
        validState(assessmentStatusStation) && (
          <div className="font-nunito mt-2 text-xs text-gray-400">
            {formatUnixTime(assessmentStatusStation.t, 'dd/MM/yyyy')}
            <br />
            {formatUnixTime(assessmentStatusStation.t, 'hh:mm a')}
          </div>
        ),
    },
    {
      Icon: InformationCircleIcon,
      completed: currentStation.id >= 6 ? true : false,
      title: 'Repair/Replace',
      content: repairStatusStation && validState(repairStatusStation) && (
        <div className="mt-2 text-xs text-gray-400 font-nunito">
          {formatUnixTime(repairStatusStation.t, 'dd/MM/yyyy')}
          <br />
          {formatUnixTime(repairStatusStation.t, 'hh:mm a')}
        </div>
      ),
    },
    {
      Icon: DocumentCheckIcon,
      completed: currentStation.id >= 10 ? true : false,
      title: 'Complete',
      content: completeStatusStation && validState(completeStatusStation) && (
        <div className="mt-2 text-xs text-gray-400 font-nunito">
          {formatUnixTime(completeStatusStation.t, 'dd/MM/yyyy')}
          <br />
          {formatUnixTime(completeStatusStation.t, 'hh:mm a')}
        </div>
      ),
    },
  ];
  let holdingImage: string | undefined;
  if (actors?.warrantor === 'W:NUCOVER') holdingImage = productImage;
  if (actors?.warrantor === 'W:PINNACLEWARRANTIES')
    holdingImage = carImagePlaceholder;
  if (!holdingImage) {
    holdingImage = Array.isArray(data.consumer_holding_image)
      ? data.consumer_holding_image[0]
      : data.consumer_holding_image;
  }

  return (
    <div
      className={classNames(dynamicStyles.container, {
        ['cursor-pointer']: onClick,
      })}
      onClick={onClick}
    >
      <div className="flex w-full gap-4">
        {holdingImage && (
          <div className="h-[90px] w-[90px] flex-shrink-0 rounded bg-white bg-contain bg-center bg-no-repeat">
            <img src={holdingImage} alt={fullProductName} />
          </div>
        )}
        <div
          className={classNames(dynamicStyles.wrapper, {
            ['md: max-w-[200px]']: showProgressDetails,
          })}
        >
          {data && (
            <>
              <div className="mb-[10px] w-full">
                <ProgressBar width={progressBarWidth} />
              </div>
              <div className="flex justify-between w-full">
                <div>
                  <p className={dynamicStyles.title}>{fullProductName}</p>
                  {data.case_last_updated && (
                    <p className={dynamicStyles.subtitle}>
                      Last updated:{' '}
                      {formatUnixTime(data.case_last_updated, 'dd/MM/yyyy')}
                    </p>
                  )}
                  <p className={dynamicStyles.text}>
                    {data.consumer_issue_type ?? ''}
                  </p>
                </div>
                {showSpotlight && (hasSpotlight || hasChatUpdate) && (
                  <div className="h-[10px] w-[10px] rounded-full bg-red-500"></div>
                )}
              </div>
            </>
          )}
        </div>
      </div>
      {showProgressDetails && (
        <div className="flex-grow w-full px-4">
          <StepsProgress steps={steps} />
        </div>
      )}
    </div>
  );
}

export default CardRepair;
