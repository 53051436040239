import { useMyConsumerPersona } from '@rabbit/data/portal';
import {
  Holding_SummariseForConsumer,
  getRootPersonaFromLexicon,
} from '@rabbit/bizproc/client';
import {
  DTConsumer_Private,
  PersonaIdTypeSplitter,
  PersonaTypeSingleLetter,
  PrincipalsFieldName,
  UserUploadedDocument,
} from '@rabbit/data/types';
import { ReactCaseFlowCase } from '../../caseflow/react-case-flow-case';
import { useConsumerHoldingEditor } from './useConsumerHoldingEditor';
import { Consumer_NewCase_AddInitialFacts } from '../../shared/opinionated';
import { t } from 'i18next';
import { useOliveAPI } from '../api/useOliveAPI';
import { useTranslation } from 'react-i18next';
import {
  CaseflowCaseTypes,
  Caseflow_CaseCreation_Olive_FormDataShape,
  RegisterCaseOrigins,
} from '@rabbit/bizproc/core';

/** For use on Olive. Create a new case flow case object with data submitted by the user,
 *  register it for react editing, and return its temporary ID.
 *  Eventually only the server function will be used, but for now client side is still used in some situations
 * This is because the server function is a proof of concept for how claims lite will be handled
 *
 *  */
export function useCreateCaseAsConsumer(holdingDocid: string) {
  const consumerPersona = useMyConsumerPersona();
  const { initiateCaseFlowCase } = useOliveAPI();
  const { t } = useTranslation();
  const tenantLink = t('tenantLink');
  const { body, isReady, update, commit } = useConsumerHoldingEditor(
    consumerPersona?.personaId || '',
    holdingDocid
  );
  console.log('consumerPersona', consumerPersona);
  console.log(holdingDocid, 'holdingDocid');

  async function createCase(
    initialCaseData: Caseflow_CaseCreation_Olive_FormDataShape,
    caseType = 'repair'
  ) {
    if (!consumerPersona.personaId || !consumerPersona.data.consumer_private)
      throw new Error('A valid consumer persona is required');
    // if (!initialCaseData.consumer_proof_of_purchase[0])
    //   throw new Error('No proof of purchase provided');

    if (!body || !isReady) return new Error('Not ready to edit holding yet!');

    // Make a new case. We set it up with the default repair configuration.
    const cfc = new ReactCaseFlowCase(
      consumerPersona.personaId,
      consumerPersona.personaId
    ); // BNC: Operator and Principal IDs are passed in separately. In consumer situation our consumer is both, but this will change for large organisation asset management (in the future) - see DEV-502.

    // Currently defaults to repair case
    await cfc.SetCaseType(caseType);

    // Set the consumer actor to the given consumer persona and the repairer to the test repairer (for now)
    cfc.Alter_Actor('consumer', consumerPersona.personaId);

    // Get information about this holding
    const holdingDataSummary = await Holding_SummariseForConsumer(holdingDocid);

    // Get information about the consumer submitting the claim
    const { email, fullname } = consumerPersona.data.consumer_private;
    if (!email || !fullname) throw new Error('Consumer has no email or name');

    // Add some basic facts (the holding the repair pertains to) + initial claim data submitted by the user

    await Consumer_NewCase_AddInitialFacts(
      cfc,
      holdingDataSummary,
      initialCaseData,
      fullname,
      email,
      holdingDocid,
      consumerPersona.personaId
    );

    // Move the case to submitted straight away, so that a proxy doc is generated;

    cfc.Alter_WithAction('_onEnter');
    const repairer = getRootPersonaFromLexicon(
      t(PrincipalsFieldName),
      PersonaTypeSingleLetter.Repairer
    );
    cfc.Alter_WithAction('submit', {
      repairer_param: repairer,
    });
    // Commit the case to the database
    await cfc.Commit();

    console.log('Created case!');
    const caseId = cfc.GetEditingID().case;

    // If the case isn't listed in the holding, add it
    if (caseId && !body?.public?.active_cases?.includes(caseId)) {
      const prevPublic = JSON.parse(JSON.stringify(body.public));
      await update({
        ...body,
        public: {
          ...prevPublic,
          active_cases: [
            ...(prevPublic.active_cases ?? []),
            cfc.GetEditingID().case,
          ],
        },
      });
      await commit();
    }

    return caseId;
  }

  async function serverCreateCase(
    formData: Caseflow_CaseCreation_Olive_FormDataShape
  ) {
    if (!consumerPersona.personaId)
      throw new Error('A valid consumer persona is required');
    const res = await initiateCaseFlowCase({
      origin: RegisterCaseOrigins.OLIVE_HOLDING,
      caseType:
        tenantLink === 'SHELTA'
          ? CaseflowCaseTypes.SHELTA
          : CaseflowCaseTypes.LITE,
      tenantLink,
      warrantorLink:
        PersonaTypeSingleLetter.Warrantor + PersonaIdTypeSplitter + tenantLink,
      holdingLink: holdingDocid,
      consumerLink: consumerPersona?.personaId,
      formData,
    });

    return res.caseId;
  }

  return { createCase, serverCreateCase };
}
