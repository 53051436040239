import { Button, Input } from '@rabbit/elements/shared-components';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { DTConsumer_Private } from '@rabbit/data/types';
import { getFirstName, getLastName, useOliveAPI } from '@rabbit/bizproc/react';
import {
  DR_Consumer_Private,
  PortalEditingDocumentStatus,
  usePortalDocumentEditor,
} from '@rabbit/data/portal';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import { DRDocType } from '@rabbit/firebase/react';
import ROUTE_NAME from '../../utils/url-constants';
import { t } from 'i18next';

interface CompleteProfileFormValuesShape {
  first_name: string;
  last_name: string;
  dob?: Date | null;
  email: string;
  opt_out_marketing: boolean;
  isCommercialUser?: string;
}

export interface FormCompleteProfileProps {
  id: string;
  data: DTConsumer_Private;
  authProvider: string | undefined;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
}

export function FormCompleteProfile({
  id,
  data,
  authProvider,
  setIsLoading,
}: FormCompleteProfileProps) {
  const tenantLink = t('tenantLink');
  const { commercialUseStatusGenerateWarranties } = useOliveAPI();
  const commercialUsersEnabled =
    t('CFG_COMMERCIAL_CONSUMER_USERS_ENABLED') === 'true';

  const schema = Yup.object({
    first_name: Yup.string()
      .required('Please enter a first name')
      .matches(
        /^(?:[A-Z][a-z]*['´`-]?\s?){1,2}|^[A-Z][a-z]*$/,
        'First name must start with a capital letter and contain only letters.'
      ),
    last_name: Yup.string()
      .trim()
      .required('Please enter a last name')
      .matches(
        /^(?:[A-Z][a-z]*['´`-]?\s?){1,2}|^[A-Z][a-z]*$/,
        'Last name must start with a capital letter and contain only letters.'
      ),
    // dob: Yup.date()
    //   //.required('Date of birth is required')
    //   .max(new Date(), 'Invalid date'),
    email: Yup.string()
      .email('Please enter a valid email address')
      .required('Please enter an email address'),

    ...(commercialUsersEnabled && {
      isCommercialUser: Yup.boolean().required('Please fill in the field'),
    }),
  });

  const { body, update, commit, isReady, status } = usePortalDocumentEditor(
    DR_Consumer_Private as DRDocType<DTConsumer_Private>,
    id
  );
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const initialValues: CompleteProfileFormValuesShape = {
    first_name:
      data.fullname && data.fullname.indexOf('@') === -1
        ? getFirstName(data.fullname)
        : '',
    last_name: data.fullname ? getLastName(data.fullname) : '',
    email: data?.email ? data?.email : '',
    opt_out_marketing: false,
  };

  const setLoadingState = (state: boolean) => {
    setLoading(state);
    setIsLoading(state);
  };

  const saveProfile = async (user: any) => {
    if (!body || !isReady) return;
    setLoadingState(true);
    try {
      update({
        ...body,
        fullname: user.fullname,
        splitname: user.splitname,
        //dob: user.dob,
        marketing: user.marketing,
        ...(typeof user.isCommercialUser === 'boolean' && {
          isCommercialUser: user.isCommercialUser,
        }),
      });
      await commit();
      if (typeof user.isCommercialUser === 'boolean') {
        console.log('Checking holdings and generating warranties');
        await commercialUseStatusGenerateWarranties({
          consumerLink: id,
          premiumTenantLink: tenantLink,
        });
      }
      setLoadingState(false);
    } catch (error) {
      console.log('error', error);
      setLoadingState(false);
    }
  };

  const onSubmit = async (values: CompleteProfileFormValuesShape) => {
    try {
      const data = {
        fullname: values.first_name + ' ' + values.last_name,
        splitname: {
          first: values.first_name,
          last: values.last_name,
        },
        marketing: values.opt_out_marketing ? false : true,
        ...(values.isCommercialUser && {
          isCommercialUser: values.isCommercialUser === 'true' ? true : false,
        }),
      };
      await saveProfile(data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (
      body &&
      isReady &&
      (authProvider === 'Google' || authProvider === 'Apple') &&
      !body?.splitname?.first &&
      !body?.splitname?.last &&
      initialValues?.first_name &&
      initialValues?.last_name
    ) {
      (async () => {
        await onSubmit(initialValues);
        navigate('/');
      })().catch((err) => console.log(err));
    }

    if (
      status === PortalEditingDocumentStatus.saved &&
      isReady &&
      !loading &&
      body?.splitname?.first &&
      body?.splitname?.last
    ) {
      window.location.reload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    body,
    body?.splitname,
    body?.splitname?.first,
    body?.splitname?.last,
    isReady,
    authProvider,
    status,
    loading,
  ]);

  return (
    <div className="flex flex-col">
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={schema}
        onSubmit={onSubmit}
      >
        {({ values, errors, touched, setFieldValue }) => (
          <Form className="flex flex-col gap-3">
            <div>
              <Input
                type="text"
                name="first_name"
                settings={{
                  id: 'first_name',
                  placeholder: 'First name',
                  hint: '*required',
                  forceUpperCaseFirstLetter: true,
                }}
              />
            </div>
            <div>
              <Input
                type="text"
                name="last_name"
                settings={{
                  id: 'last_name',
                  placeholder: 'Last name',
                  hint: '*required',
                  forceUpperCaseFirstLetter: true,
                }}
              />
            </div>
            {/* <div>
              <Input
                type="datepicker"
                name="dob"
                settings={{
                  id: 'dob',
                  maxDate: subtractYears(new Date(), 18),
                  initialDate: subtractYears(new Date(), 18),
                  placeholder: 'Date of birth',
                }}
              />
            </div> */}
            <div>
              <Input
                type="email"
                name="email"
                settings={{
                  id: 'email',
                  disabled: true,
                  hint: '*required',
                }}
              />
            </div>
            {commercialUsersEnabled && (
              <div>
                <p className="font-nunito pb-2 font-medium">
                  Will you be using your products for commercial purposes?{' '}
                </p>
                <Input
                  type="radio"
                  name="isCommercialUser"
                  settings={{
                    values,
                    // Formik didn't like me using a boolean here, so this is a string so I don't waste too much time here - DC
                    radioValue: 'true',
                    radioLabel: 'Yes',
                    radioLabelStyles: 'font-nunito font-medium text-gray-500',
                  }}
                />
                <Input
                  type="radio"
                  name="isCommercialUser"
                  settings={{
                    values,
                    radioValue: 'false',
                    radioLabel: 'No',
                    radioLabelStyles: 'font-nunito font-medium text-gray-500',
                  }}
                />
              </div>
            )}
            <div>
              <p className="font-nunito font-medium">
                iWarranty will occasionally send you promotional emails. You can
                opt out at any time in your account settings or directly from
                the marketing email.
              </p>
            </div>
            <div className="font-nunito">
              <Input
                type="checkbox"
                name="opt_out_marketing"
                settings={{
                  checkboxLabel: 'Please don’t send me promotional emails.',
                }}
              />
            </div>
            <div className="font-nunito fixed bottom-0 left-0 min-w-full rounded-tl-[30px] rounded-tr-[30px] bg-gray-100 font-medium md:relative md:bg-transparent">
              <div className="px-10 py-5 md:px-0 md:py-0">
                <div className="mt-4 flex">
                  <Button
                    kind="primary"
                    type="submit"
                    disabled={Object.keys(errors).length > 0}
                    loading={loading}
                  >
                    {authProvider ? `Sign up with ${authProvider}` : 'Sign up'}
                  </Button>
                </div>
                <div className="mt-4 text-center">
                  By continuing, you agree to iWarranty’s <br />
                  <NavLink to={ROUTE_NAME.TERMS_AND_CONDITIONS}>
                    Terms & Conditions
                  </NavLink>
                  &nbsp;and&nbsp;
                  <NavLink to={ROUTE_NAME.PRIVACY_POLICY}>
                    Privacy Policy
                  </NavLink>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default FormCompleteProfile;
