import { useContext, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  DesktopContainer,
  LoadingSpinner,
} from '@rabbit/elements/shared-components';

import { UserContext } from 'apps/olive/src/context/UserContext';
import { OliveFileUploader } from '@rabbit/olive/components/organisms/upload-wrapper/OliveFileUploader';
import { UploadedFileCategories } from '@rabbit/elements/shared-types';

/* eslint-disable-next-line */
export interface HoldingSingleAddEditProofPurchaseProps {}

export function HoldingSingleAddEditProofPurchase(
  props: HoldingSingleAddEditProofPurchaseProps
) {
  const location = useLocation();
  const holdingId = location.pathname.split('/')[2];
  const navigate = useNavigate();
  const { consumerPersonaId } = useContext(UserContext) || {};

  if (!consumerPersonaId) return <LoadingSpinner size="sm" />;

  return (
    <DesktopContainer title="Proof of purchase" goBack={() => navigate(-1)}>
      <OliveFileUploader
        label="Proof of purchase"
        identifiers={{
          category: UploadedFileCategories.ConsumerProofPurchase,
          docType: { docid: holdingId, type: 'holding' },
          personaId: consumerPersonaId ?? '',
        }}
        shouldAutoUpdateDocs={true}
        accepts={['image/*', '.pdf']}
      />
    </DesktopContainer>
  );
}

export default HoldingSingleAddEditProofPurchase;
