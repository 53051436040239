import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { UserContext } from '../context/UserContext';
import {
  Button,
  Heading,
  LoadingSpinner,
} from '@rabbit/elements/shared-components';
import ROUTE_NAME from '../utils/url-constants';
import logoiWarranty from '../assets/images/logo/logo-iwarranty-black.svg';
import logoiWarrantyWhite from '../assets/images/logo/logo-iwarranty-white.svg';
import macbook from '../assets/images/macbook-iphone-2.png';
import Footer from '../components/molecules/Footer/Footer';
import { MinusIcon, PlusIcon } from '@heroicons/react/24/outline';
import { getGreeting } from '@rabbit/bizproc/react';
import { SearchVendablesByUpcCode } from '@rabbit/search/cherchons';
import { DTVendable } from '@rabbit/data/types';
import { useAppInfo } from '../utils/helpers';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

/* eslint-disable-next-line */
export interface LandingRegisterProductViewProps {}

export function LandingRegisterProductView(
  props: LandingRegisterProductViewProps
) {
  const navigate = useNavigate();
  const { id: vendable_upc } = useParams();
  const { consumerPersonaData } = useContext(UserContext) || {};
  const [selected, setSelected] = useState(null);
  const [vendableData, setVendableData] = useState<DTVendable | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const appInfo = useAppInfo();
  const { t } = useTranslation();

  const productPlaceHolder = t(
    'CFG_COBRAND_LANDING_IMAGE_PRODUCT_PLACEHOLDER',
    { defaultValue: '' }
  );
  const sectionBg = t('CFG_COBRAND_LANDING_IMAGE_BACKGROUND', {
    defaultValue: '',
  });
  const sectionTopRight = t('CFG_COBRAND_LANDING_IMAGE_TOP_RIGHT', {
    defaultValue: '',
  });
  const sectionBottomLeft = t('CFG_COBRAND_LANDING_IMAGE_BOTTOM_LEFT', {
    defaultValue: '',
  });
  const sectionBottom = t('CFG_COBRAND_LANDING_IMAGE_BOTTOM_SECTION', {
    defaultValue: '',
  });

  // UPC in Params will come from Shelta as an incomplete version of the barcode number, which needs to be prefixed with the constant below
  const UPC_PREFIX = t('CFG_COBRAND_UPC_PREFIX', { defaultValue: '' });

  useEffect(() => {
    if (vendable_upc) {
      (async () => {
        const res = await SearchVendablesByUpcCode(
          `${UPC_PREFIX}${vendable_upc}`
        );
        if (res) setVendableData(res?.items[0]);
        console.log(res);
      })()
        .catch((err) => console.log(err))
        .finally(() => setIsLoading(false));
    } else {
      setIsLoading(false);
    }
  }, [vendable_upc]);

  const handleToggleDescription = (itemId: any) => {
    setSelected((prevSelected) => (prevSelected === itemId ? null : itemId));
  };

  const items = [
    {
      id: 1,
      title: 'Warranties at your fingertips',
      description:
        'No more fine print, we summarize the important parts for you.',
      border: true,
      Icon: PlusIcon,
    },
    {
      id: 2,
      title: 'Easy claim process',
      description:
        'Effortlessly initiate claims and repairs of your ' +
        appInfo.name +
        ' products at the tap of an app.',
      border: true,
      Icon: PlusIcon,
    },
    {
      id: 3,
      title: 'Free to use',
      description:
        'Experience peace of mind in managing warranties, with no service fees.',
      border: false,
      Icon: PlusIcon,
    },
  ];

  const onClickRegisterProduct = () => {
    if (!vendableData) {
      if (consumerPersonaData) navigate(ROUTE_NAME.PRODUCTS_SEARCH);
      else navigate(ROUTE_NAME.AUTH);
    } else {
      if (consumerPersonaData) {
        navigate(
          ROUTE_NAME.PRODUCTS_PREVIEW + '/' + vendableData?.docid + '/register'
        );
      } else {
        window.localStorage.setItem('LRP_VendableId', vendableData.docid);
        window.localStorage.setItem('LRP_VendableName', vendableData.title);
        navigate(ROUTE_NAME.AUTH);
      }
    }
  };

  if (isLoading || !appInfo.name) return <LoadingSpinner size={'sm'} />;

  return (
    <div className="w-full flex-col">
      <div className="mx-auto max-w-[1326px] px-4 lg:mb-[140px]">
        <div className="mt-9 mb-4 flex flex-wrap items-center justify-between md:mb-[70px] md:flex-nowrap">
          <div className="flex w-1/2">
            <img
              src={logoiWarranty}
              alt={'iWarranty'}
              className="max-w-[110px] lg:max-w-[146px]"
            ></img>
            <div className="mx-5 block border-r-2 text-gray-900"></div>
            {appInfo.logo ? (
              <img
                src={appInfo.logo}
                alt={'logo'}
                className="max-h-[40px] max-w-[200px]"
              ></img>
            ) : null}
          </div>
          {!consumerPersonaData ? (
            <Button
              kind="primary"
              size="sm"
              className="max-w-[65px]"
              onClick={() => navigate(ROUTE_NAME.NON_LOGGED_CONTINUE_CLAIM)}
            >
              Login
            </Button>
          ) : (
            <Heading
              kind={'h4'}
              className="mt-2 w-full md:mt-0 md:w-1/2 md:text-right"
            >
              Hi{' '}
              {consumerPersonaData?.splitname?.first ||
                consumerPersonaData?.fullname}
              , good {getGreeting()}.
            </Heading>
          )}
        </div>

        <div className="mb-[50px] flex-col text-center lg:mb-[94px] lg:flex lg:flex-row-reverse lg:justify-between lg:gap-[86px] lg:text-left">
          <div
            style={{
              backgroundImage: vendableData?.img?.[0]
                ? 'url(' + vendableData.img?.[0] + ')'
                : 'url(' + productPlaceHolder + ')',
            }}
            className="mx-auto max-h-[388px] min-h-[154px] w-full max-w-[420px] rounded-lg  bg-contain bg-center bg-no-repeat lg:rounded-none"
          ></div>
          <div className="flex w-full flex-col gap-16">
            <div className="flex flex-col gap-4">
              <p className="font-caudex text-2xl font-bold md:py-3 lg:max-w-[726px] lg:text-5xl">
                {vendableData?.title ??
                  `Register your ${appInfo.short_name} product today`}
              </p>
              <p className="font-nunito text-lg text-gray-700 lg:text-2xl">
                {appInfo.name} has partnered with iWarranty to give you
                convenience and peace of mind with your purchase.
              </p>
            </div>
            <Button
              kind="primary"
              onClick={onClickRegisterProduct}
              className="h-[69px] px-8 lg:w-max"
            >
              Register your product
            </Button>
          </div>
        </div>

        <div className="relative z-[1]">
          <div className="relative mx-auto flex-col overflow-hidden rounded-[10px] bg-white py-[89px] text-center shadow-[0px_2px_7px_-6px_rgba(16,24,40,0.08),0px_9px_38px_0px_rgba(0,0,0,0.08)]">
            <div className="z-0">
              {sectionBg ? (
                <div
                  className="absolute right-0 top-0 h-full w-full"
                  style={{
                    backgroundImage: `url(${sectionBg})`,
                    backgroundPosition: 'top right',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    top: 0, // Add this line to align the element at the bottom
                  }}
                ></div>
              ) : null}
              {sectionTopRight ? (
                <div
                  className="absolute right-0 top-0 h-[20%] w-full md:h-[50%]"
                  style={{
                    backgroundImage: `url(${sectionTopRight})`,
                    backgroundPosition: 'top right',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'contain',
                    top: 0, // Add this line to align the element at the bottom
                  }}
                ></div>
              ) : null}
              {sectionBottomLeft ? (
                <div
                  className="absolute left-0 bottom-0 h-[20%] w-full md:h-[50%]"
                  style={{
                    backgroundImage: `url(${sectionBottomLeft})`,
                    backgroundPosition: 'bottom left',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'contain',
                    bottom: 0, // Add this line to align the element at the bottom
                  }}
                ></div>
              ) : null}
            </div>
            <div
              className={classNames(
                'relative z-10 px-16 md:px-32 lg:px-[150px] lg:text-5xl',
                {
                  ['text-white']: sectionBg,
                }
              )}
            >
              <p className="font-caudex text-3xl">
                Get the best out of your products with <b>{appInfo.name}</b> and{' '}
                <b>iWarranty</b>
              </p>
              <div className="gap-5.5 mt-8 flex items-center justify-center">
                {appInfo.logo ? (
                  <img
                    src={appInfo.logo}
                    className={classNames('h-[30px]', {
                      ['invert']: sectionBg,
                    })}
                    alt={appInfo.name}
                  ></img>
                ) : null}
                <div className="mx-5 h-[30px] border-r-2 text-gray-900"></div>
                <img
                  src={sectionBg ? logoiWarrantyWhite : logoiWarranty}
                  alt={'iWarranty Logo'}
                  className="w-[102px] lg:w-[146px]"
                ></img>
              </div>
            </div>
          </div>
          <div
            className="absolute -bottom-16 -z-[1] h-[120px] w-[110%] -translate-x-[5%] bg-red-700"
            style={{
              borderRadius: '1630.379px',
              opacity: 0.4,
              background:
                'linear-gradient(85deg, #C5F9E3 3.66%, #F5F786 81.3%, #F27AD8 160.58%)',
              filter: 'blur(100px)',
            }}
          ></div>
        </div>
        <div className="mt-10 mb-4 flex-col lg:mt-[179px]">
          <p className="font-caudex text-3xl lg:text-5xl">
            How can <b>{appInfo.name}</b> and <b>iWarranty</b> provide you the
            best post-purchase experience ?
          </p>
        </div>

        <div className="mx-auto lg:mb-20 lg:flex">
          <div className="flex-col lg:w-[700px] lg:flex-1">
            <p className="font-nunito pb-[18px] text-xl text-gray-700">
              Within iWarranty’s platform you can store, track and manage all
              your {appInfo.name} products and warranties in one place.
              Accessible from anywhere, on any device, at any time.
            </p>

            {items &&
              items.map((item, index) => (
                <div key={index} className="flex flex-col">
                  <div
                    className="mt-[26px] flex cursor-pointer items-center justify-between pb-4"
                    onClick={() => handleToggleDescription(item.id)}
                  >
                    <div className="flex flex-1 items-start">
                      <div className="font-caudex text-2xl font-normal lg:text-[42px]">
                        {item.title}
                      </div>
                    </div>
                    <div className="h-4 w-4">
                      {selected === item.id ? <MinusIcon /> : <PlusIcon />}
                    </div>
                  </div>
                  <div className="flex w-full flex-col">
                    {selected === item.id && (
                      <span className="font-nunito text-base font-normal lg:text-2xl">
                        {item.description}
                      </span>
                    )}
                  </div>
                  {item.border && (
                    <div className="w-full border-b-2 pt-[26px] text-gray-900"></div>
                  )}
                </div>
              ))}
          </div>
          {sectionBottom ? (
            <div className="relative lg:relative lg:flex-1">
              <img
                src={sectionBottom}
                alt={''}
                className="lg: top-[-50px] lg:absolute lg:left-[50px] lg:max-w-[820px]"
              ></img>
            </div>
          ) : null}
        </div>
      </div>

      <div className="bg-primary-900 mb-8 flex flex-col justify-center gap-6 py-[52px] px-[15px] lg:hidden">
        <p className="font-caudex text-center text-2xl font-bold text-white">
          Ready to get started?
        </p>
        <Button kind="outline" onClick={onClickRegisterProduct}>
          Register my product
        </Button>
      </div>

      <Footer />
    </div>
  );
}

export default LandingRegisterProductView;
