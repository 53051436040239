import { useParams } from 'react-router-dom';
import VendableInfoContainer from '../../../components/molecules/VendableInfoContainer/VendableInfoContainer';

interface VendablePreviewViewProps {
  vendableId?: string;
  onChangeStep?: (step_in: number, data?: any) => void;
}

export function VendablePreviewView({
  vendableId,
  onChangeStep,
}: VendablePreviewViewProps) {
  const { id } = useParams();

  // Not logged in
  if (vendableId)
    return (
      <VendableInfoContainer onChangeStep={onChangeStep} docid={vendableId} />
    );

  // Logged in
  if (id) return <VendableInfoContainer docid={id} />;
  return null;
}

export default VendablePreviewView;
