/* eslint-disable @typescript-eslint/ban-ts-comment */
import { BL_Warranty } from '@rabbit/bizproc/core';
import { DTHoldingProxy, Warranty } from '@rabbit/data/types';
import { add, fromUnixTime, getUnixTime, sub } from 'date-fns';
import { useTranslation } from 'react-i18next';

export const useAppInfo = () => {
  const { t } = useTranslation();

  const getProperty = (key: string) => {
    return t(key) !== key ? t(key) : null;
  };

  //@ts-ignore
  const business_name = getProperty('CFG_COBRAND_NAME') ?? '';
  const short_name = getProperty('CFG_COBRAND_SHORT_NAME') ?? business_name;
  //@ts-ignore
  const environment = import.meta.env.VITE_FIREBASE_MODE;
  //@ts-ignore
  const logo = getProperty('CFG_COBRAND_LOGO') ?? '';
  //@ts-ignore
  const currency =
    getProperty('CFG_DEFAULT_CURRENCY') ?? import.meta.env.VITE_DEFAULT_COUNTRY;
  //@ts-ignore
  const country =
    getProperty('CFG_DEFAULT_COUNTRY') ?? import.meta.env.VITE_DEFAULT_COUNTRY;
  const email_sender =
    getProperty('CFG_COBRAND_EMAIL_SENDER') ?? 'no-reply@global.iwarranty.co';
  const email_main_template =
    getProperty('CFG_COBRAND_EMAIL_MAIN_TEMPLATE') ??
    'main_header_footer_default';

  const data = {
    name: business_name ?? '',
    short_name: short_name ?? '',
    environment: environment ?? 'ENVIRONMENT NOT SET',
    logo: logo,
    country: country ?? 'AU',
    currency: currency ?? 'AUD',
    address: getBusinessDefaultAddress(),
    email_sender,
    email_main_template,
  };

  return data;
};

export const getBusinessDefaultAddress = () => {
  const { t } = useTranslation();

  const getProperty = (key: string) => {
    return t && t(key) !== key ? t(key) : null;
  };

  const addresses = getProperty('CFG_COBRAND_ADDRESSES') ?? [];
  const parsedAddresses = addresses
    ? typeof addresses === 'string'
      ? JSON.parse(addresses)
      : addresses
    : [];
  if (!parsedAddresses.length) return null;
  const defaultAddress = parsedAddresses.find(
    (address: any) => address.isDefault
  );
  //if doesn't find  default address, return the first one
  if (!defaultAddress) {
    return parsedAddresses[0];
  }

  return defaultAddress;
};

export const checkCodeScan = (url: string) => {
  const pattern = /https:\/\/shelta\.com\.au\/wp-json\/w\/(\d+)/;
  const match = url.match(pattern);

  if (match && match[1]) {
    return match[1];
  } else {
    return null; // URL doesn't match the expected pattern
  }
};

export const calcWarrantyExpiryDate = (
  warranties: Warranty[],
  purchase_time: number
) => {
  const warranty = BL_Warranty.getLatestWarranty(warranties);
  if (!purchase_time || !warranty) return undefined;
  const expiry_date = getUnixTime(
    sub(
      add(fromUnixTime(purchase_time), {
        [warranty.duration.division]: warranty.duration.amount,
      }),
      {
        days: 1,
      }
    )
  );
  return expiry_date;
};

export const isValidDate = (d: Date) => d instanceof Date && !isNaN(d as any);

export const fixCategoryAndSortHoldingsByTCreate = (data: DTHoldingProxy[]) => {
  return data
    .sort((a: DTHoldingProxy, b: DTHoldingProxy) => {
      const aCreate = new Date(a.tcreate);
      const bCreate = new Date(b.tcreate);
      return bCreate.getTime() - aCreate.getTime();
    })
    .map((holding) => {
      return {
        ...holding,
        category: getHoldingCategory(holding),
      };
    });
};

export const getWarrantyInfo = (appName: string) => {
  switch (appName) {
    case 'Shelta Australia':
      return import('apps/olive/src/utils/warranty-info/shelta_australia');
    case 'MasterSounds':
      return import('apps/olive/src/utils/warranty-info/mastersounds');
    case 'Union Audio Limited':
      return import('apps/olive/src/utils/warranty-info/unionaudio');
    case 'Charlie Oven':
      return import('apps/olive/src/utils/warranty-info/charlieoven');
    case 'Warranty Ireland':
      return import('apps/olive/src/utils/warranty-info/warrantyireland');
    case 'Nu-Cover':
      return import('apps/olive/src/utils/warranty-info/nucover');
    case 'Pinnacle Warranties':
      return import('apps/olive/src/utils/warranty-info/pinnaclewarranties');
    // add more cases for other app names
    default:
      return import('apps/olive/src/utils/warranty-info/shelta_australia');
  }
};

export const getStoneImages = (stoneName: string): string | undefined => {
  const stones: Record<string, string> = {
    Marble:
      'https://firebasestorage.googleapis.com/v0/b/eulaunchpad-firerabbit.appspot.com/o/tenants%2Fnucover%2Fstones%2FMarble.jpg?alt=media&token=97b65825-1c9d-48ee-ad40-d91bd009716d',
    Granite:
      'https://firebasestorage.googleapis.com/v0/b/eulaunchpad-firerabbit.appspot.com/o/tenants%2Fnucover%2Fstones%2FGranite.jpg?alt=media&token=aabc69f1-b163-4e90-a98d-89033356e86a',
    Limestone:
      'https://firebasestorage.googleapis.com/v0/b/eulaunchpad-firerabbit.appspot.com/o/tenants%2Fnucover%2Fstones%2FLimestone.jpg?alt=media&token=671d3d8c-b6f3-4af9-b21e-8f1c2b29c6f9',
    'Engineered Stone':
      'https://firebasestorage.googleapis.com/v0/b/eulaunchpad-firerabbit.appspot.com/o/tenants%2Fnucover%2Fstones%2FEngineered%20Stone.jpg?alt=media&token=fee1d5a1-d2d4-4db7-985d-607365ac84c7',
    Quartzite:
      'https://firebasestorage.googleapis.com/v0/b/eulaunchpad-firerabbit.appspot.com/o/tenants%2Fnucover%2Fstones%2FQuartzite.jpg?alt=media&token=f4fb21ec-4b84-4855-9922-b29db424af51',
    Sandstone:
      'https://firebasestorage.googleapis.com/v0/b/eulaunchpad-firerabbit.appspot.com/o/tenants%2Fnucover%2Fstones%2FSandstone.jpg?alt=media&token=15561661-aca1-489a-b929-3e6a533796fa',
    Slate:
      'https://firebasestorage.googleapis.com/v0/b/eulaunchpad-firerabbit.appspot.com/o/tenants%2Fnucover%2Fstones%2FSlate.jpg?alt=media&token=cae8a023-d7a9-48e1-975c-ac4cf5688b31',
    Travertine:
      'https://firebasestorage.googleapis.com/v0/b/eulaunchpad-firerabbit.appspot.com/o/tenants%2Fnucover%2Fstones%2FTravertine.jpg?alt=media&token=704ec6cb-e2ce-45ab-88bc-00ac59461f39',
    Porcelain:
      'https://firebasestorage.googleapis.com/v0/b/eulaunchpad-firerabbit.appspot.com/o/tenants%2Fnucover%2Fstones%2FPorcelain.png?alt=media&token=6285ccdd-b55d-4f22-b60a-9e181faac537',
  };
  return stones[stoneName];
};

export const getHoldingCategory = (holding: DTHoldingProxy) => {
  if (
    holding.self_registration &&
    typeof holding.self_registration !== 'boolean'
  ) {
    return holding?.self_registration?.category_title?.replace(/-/g, ' ');
  } else {
    if (holding.category && Array.isArray(holding.category)) {
      return holding.category[0].replace(/-/g, ' ');
    } else {
      return holding.category?.replace(/-/g, ' ');
    }
  }
};
