import { useContext, useEffect, useState } from 'react';
import { UserContext } from '../context/UserContext';
import { Navigate, useNavigate } from 'react-router-dom';
import {
  CardImageBg,
  Heading,
  HomeTitleWithCTA,
  LayoutGrid,
  CardGeneral,
  EmptyListCTA,
  CardRepair,
} from '@rabbit/elements/shared-components';
import {
  getGreeting,
  useGetConsumerHoldings,
  useGetConsumerCases,
} from '@rabbit/bizproc/react';
import noProductsImg from '../assets/images/no_products_yet.svg';
import ROUTE_NAME from '../utils/url-constants';
//import yolo from '../assets/images/yolo.jpg';
import bg_motd from '../assets/images/bg_motd.jpg';
import { DTHoldingProxy, TableCover } from '@rabbit/data/types';
import {
  useAppInfo,
  fixCategoryAndSortHoldingsByTCreate,
  getStoneImages,
} from '../utils/helpers';
import Skeleton from 'react-loading-skeleton';
import { useTranslation } from 'react-i18next';

export function DashboardView() {
  const navigate = useNavigate();
  const { consumerPersonaData, consumerPersonaId } =
    useContext(UserContext) || {};
  const { getAllHoldingProxies } = useGetConsumerHoldings(
    consumerPersonaId || ''
  );
  const { openCaseIds } = useGetConsumerCases() || {};

  const [isLoading, setIsLoading] = useState(true);
  const [userHoldings, setUserHoldings] = useState<DTHoldingProxy[]>([]);
  const appInfo = useAppInfo();
  const { t } = useTranslation();
  let tenantType = 'defaultTenants';
  if (t('tenantLink') === 'NUCOVER') tenantType = 'tableTenants';

  const getCases = () => {
    return openCaseIds?.slice(0, 9)?.map((singleCase) => {
      return (
        <CardRepair
          key={singleCase.case}
          id={singleCase}
          onClick={() => navigate(`${ROUTE_NAME.REPAIRS}/${singleCase.case}`)}
          showSpotlight
          getStoneImages={getStoneImages}
        />
      );
    });
  };

  useEffect(() => {
    // TODO: Update this to avoid constant loading states. Eg: show stale data and update it later. React Query?
    setIsLoading(true);

    if (consumerPersonaData) {
      const getHoldingProxies = async () => {
        const holdings: DTHoldingProxy[] | Error = await getAllHoldingProxies();
        if (holdings instanceof Error) setUserHoldings([]);
        else setUserHoldings(fixCategoryAndSortHoldingsByTCreate(holdings));
        setIsLoading(false);
      };

      getHoldingProxies().catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!consumerPersonaData) return <Navigate to={ROUTE_NAME.AUTH} />;

  return (
    <div className="lg:mb-10">
      <div className="flex items-center justify-between">
        <Heading kind={'h3'}>
          Hi{' '}
          {consumerPersonaData?.splitname?.first ||
            consumerPersonaData?.fullname}
          , good {getGreeting()}.
        </Heading>
      </div>
      {openCaseIds?.length > 0 && (
        <div className="mt-6 md:mt-10">
          <HomeTitleWithCTA
            hasTitle={true}
            hasSubtitle={false}
            title={'Repairs in progress'}
          />
          <div className="mt-4 md:mt-7">
            <div className="grid grid-cols-1 gap-5 md:grid md:grid-cols-2">
              {getCases()}
            </div>
          </div>
        </div>
      )}
      <div className="mt-7">
        <HomeTitleWithCTA
          hasTitle={true}
          hasSubtitle={false}
          title={'Recent uploads'}
          onClick={() => navigate(ROUTE_NAME.PRODUCTS_SEARCH)}
        />
        <div className="mt-5">
          {isLoading && (
            <div className="grid grid-cols-1 gap-5 md:grid md:grid-cols-2">
              <Skeleton count={1} className="min-h-[128px] w-full" />
              <Skeleton count={1} className="min-h-[128px] w-full" />
            </div>
          )}
          {userHoldings.length === 0 && !isLoading && (
            <div className="mx-auto w-full sm:w-2/3 md:w-1/2">
              <EmptyListCTA
                copy={'No products yet! Click the button above to get started.'}
                imgSrc={noProductsImg}
                imgAlt={'emptyList'}
              />
            </div>
          )}
          {userHoldings.length > 0 && (
            <div className="grid grid-cols-1 gap-5 md:grid md:grid-cols-2">
              {userHoldings
                //.reverse()
                .slice(0, 4)
                .map((holding) => {
                  if (holding.deleted) return null;
                  const productInfo = holding?.self_registration?.srvInfo
                    ?.productInfo as TableCover;
                  const imageUrl = productInfo?.typeofStone
                    ? getStoneImages(productInfo?.typeofStone)
                    : holding.img?.[0] ||
                      holding.self_registration?.img?.[0] ||
                      '';
                  return (
                    <div key={holding.docid.split('_')[0]}>
                      <CardGeneral
                        onClick={() =>
                          navigate(
                            `${ROUTE_NAME.PRODUCTS}/${
                              holding.docid.split('_')[0]
                            }/details`
                          )
                        }
                        holdingData={holding}
                        imageUrl={imageUrl}
                        appInfo={appInfo}
                      />
                    </div>
                  );
                })}
            </div>
          )}
          {/* <LayoutGrid kind={'uploads'} items={userHoldings}></LayoutGrid> */}
        </div>
      </div>
      <div className="mt-8">
        <HomeTitleWithCTA
          hasTitle={true}
          hasSubtitle={false}
          title={'Message of the moment'}
        />
        <div className="mt-5">
          <CardImageBg
            dailyMsg={true}
            message={
              'Repair cafes save 24 kgCO2e per repair by avoiding product replacement in 88% of cases.'
            }
            imageUrl={bg_motd}
          />
        </div>
      </div>
      {/* Keep the Shelta string hardcoded for exceptions - VP */}
      {appInfo?.name === 'Shelta Australia ' && (
        <>
          <div className="mt-8">
            <HomeTitleWithCTA
              hasTitle={true}
              hasSubtitle={true}
              title={'Suggestions'}
            />
            <div className="mt-4">
              <LayoutGrid kind={'suggestions'} />
            </div>
          </div>
          <div className="mt-8">
            <HomeTitleWithCTA
              hasTitle={false}
              hasSubtitle={true}
              subTitle={'Other amazing suggestions'}
            />
            <div className="mt-4 lg:mt-8">
              <LayoutGrid kind={'otherSuggestions'} />
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default DashboardView;
