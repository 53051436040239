import {
  APICallParamsBase,
  Shelta_RegisterHoldingParams,
  Shelta_RegisterHoldingResult,
} from '@rabbit/elements/shared-types';
import { useAPI } from './useAPI';
import {
  Auth_CheckSagePersonaExistence_WithEmail,
  CaseFlow_InitiateCase,
  Shelta_RegisterHolding,
  Warranty_GenerateFromCommercialStatus,
} from '@rabbit/bizproc/client';
import {
  ICF_CheckSagePersonaExistence_WithEmail,
  ICF_CommercialUseStatusWarrantyGeneration,
  ICF_RegisterCase,
  OCF_CheckSagePersonaExistence_WithEmail,
  OCF_CommercialUseStatusWarrantyGeneration,
  OCF_RegisterCase,
} from '@rabbit/bizproc/core';
import { z } from 'zod';

export function useOliveAPI() {
  const {
    // requestId,
    // apiVersion,
    isReady,
    callAPI,
  } = useAPI();

  // Import the functions, wrap them in callAPI, and return them

  /* -------------------------------------------------------------------------- */
  /*                    Sage persona existence check on login                   */
  /* -------------------------------------------------------------------------- */
  async function checkSagePersonaExistenceWithEmail(
    params: Omit<
      z.infer<typeof ICF_CheckSagePersonaExistence_WithEmail>,
      keyof APICallParamsBase
    >
  ): Promise<z.infer<typeof OCF_CheckSagePersonaExistence_WithEmail>> {
    return await callAPI(Auth_CheckSagePersonaExistence_WithEmail, params);
  }

  /* -------------------------------------------------------------------------- */
  /*                            Holding registration                            */
  /* -------------------------------------------------------------------------- */

  // Might be deprecated in the future in favour of a more generic function
  async function registerSheltaHolding(
    params: Shelta_RegisterHoldingParams
  ): Promise<Shelta_RegisterHoldingResult['data']> {
    return await callAPI(Shelta_RegisterHolding, params);
  }

  async function initiateCaseFlowCase(
    params: Omit<z.infer<typeof ICF_RegisterCase>, keyof APICallParamsBase>
  ): Promise<z.infer<typeof OCF_RegisterCase>> {
    return await callAPI(CaseFlow_InitiateCase, params);
  }

  /* -------------------------------------------------------------------------- */
  /*                  Commercial use status warranty generation                 */
  /* -------------------------------------------------------------------------- */
  async function commercialUseStatusGenerateWarranties(
    params: Omit<
      z.infer<typeof ICF_CommercialUseStatusWarrantyGeneration>,
      keyof APICallParamsBase
    >
  ): Promise<z.infer<typeof OCF_CommercialUseStatusWarrantyGeneration>> {
    return await callAPI(Warranty_GenerateFromCommercialStatus, params);
  }

  return {
    isReady,
    // Sage persona existence check on login
    checkSagePersonaExistenceWithEmail,
    // Holding registration
    registerSheltaHolding,
    // Caseflow case creation
    initiateCaseFlowCase,
    // Commercial use status warranty generation
    commercialUseStatusGenerateWarranties,
  };
}
