import {
  DesktopContainer,
  LoadingSpinner,
  WarrantyInfo,
} from '@rabbit/elements/shared-components';
import { useLocation, useNavigate } from 'react-router-dom';
import foxImage from 'apps/olive/src/assets/images/confused_fox.png';
import magicOwlImage from 'apps/olive/src/assets/images/magic_owl.png';
import { useConsumerHoldingEditor } from '@rabbit/bizproc/react';
import { useMyConsumerPersona } from '@rabbit/data/portal';
import { getWarrantyInfo, useAppInfo } from '@rabbit/olive/utils/helpers';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { WARRANTY_INFO_PATH } from '@rabbit/olive/utils/consts';

/* eslint-disable-next-line */
export interface HoldingSingleWarrantyInfoViewProps {}

export interface ItemProps {
  id?: string;
  title: string;
  description?: string;
  included?: string;
}

export interface InfoInterface {
  title: string;
  type: string;
  items?: ItemProps[];
  children?: any;
}

export function HoldingSingleWarrantyInfoView(
  props: HoldingSingleWarrantyInfoViewProps
) {
  const location = useLocation();
  const { t } = useTranslation();
  const holdingId = location.pathname.split('/')[2];
  const persona = useMyConsumerPersona();
  const { body } = useConsumerHoldingEditor(
    persona?.personaId ?? '',
    holdingId
  );

  if (!body) return <LoadingSpinner size="md" extraClasses="mt-5" />;

  const selfRegistered = body?.public?.self_registration ?? false;
  // Disabled until a database of manufacturers and their claim urls is created
  const manufacturer_claim_url = undefined; // "https://google.com/product/claim"
  const manufacturer_name = body?.public?.self_registration?.brand ?? null;
  const product_name = body?.public?.self_registration?.title ?? null;

  const navigate = useNavigate();
  const appInfo = useAppInfo();

  // if (selfRegistered) {
  //   return (
  //     <div className="flex flex-wrap items-start justify-center">
  //       <div className="w-full md:basis-1/2">
  //         <DesktopContainer
  //           title="Warranty information"
  //           goBack={() => navigate(-1)}
  //         >
  //           {manufacturer_claim_url ? (
  //             <div className="flex min-h-[540px] flex-col gap-6">
  //               <img
  //                 src={magicOwlImage}
  //                 className="mx-auto"
  //                 alt="image of a magical owl on a broomstick scooter"
  //                 width={'163px'}
  //                 height={'174px'}
  //               />
  //               <p className="font-nunito text-small mx-auto w-[80%] text-center text-gray-700">
  //                 We're sorry, we have not yet integrated with{' '}
  //                 <strong>{manufacturer_name}</strong> but we're working on it.
  //                 <br />
  //                 <br />
  //                 To access warranty information of your{' '}
  //                 <strong>{manufacturer_name}</strong>{' '}
  //                 <strong>{product_name}</strong> please visit &nbsp;
  //                 <a href={manufacturer_claim_url} target="_blank">
  //                   {manufacturer_claim_url}
  //                 </a>
  //                 .
  //               </p>
  //             </div>
  //           ) : (
  //             <div className="flex min-h-[540px] flex-col gap-6">
  //               <img
  //                 src={foxImage}
  //                 className="mx-auto"
  //                 alt="image of confused fox"
  //                 width={'160px'}
  //                 height={'160px'}
  //               />
  //               <p className="font-nunito text-small text-center text-gray-700">
  //                 We're sorry, <strong>{manufacturer_name}</strong> isn’t
  //                 connected to iWarranty yet. We're working hard to add more
  //                 manufacturers and we have recorded that we don't have any
  //                 information for <strong>{manufacturer_name}</strong>.
  //               </p>
  //             </div>
  //           )}
  //         </DesktopContainer>
  //       </div>
  //     </div>
  //   );
  // }

  const [warrantyInfo, setWarrantyInfo] = useState<InfoInterface[]>([]);
  const [warrantyTerms, setWarrantyTerms] = useState<InfoInterface[]>([]);

  useEffect(() => {
    const getWarranty = async () => {
      const warranty = await getWarrantyInfo(appInfo.name);
      if (warranty) {
        setWarrantyInfo(warranty.warranty_info);
        setWarrantyTerms(warranty.warranty_terms);
      }
    };
    getWarranty();
  }, []);

  return (
    <div className="flex flex-wrap items-start justify-center">
      <div className="w-full md:basis-1/2">
        <DesktopContainer
          title={
            appInfo.name === 'NuCover'
              ? 'Care Plan Summary'
              : 'Warranty Information'
          }
          goBack={() => navigate(-1)}
        >
          {warrantyInfo
            .filter(
              (data: InfoInterface) => data.title !== 'Consumer Guarantee'
            )
            .map((data: InfoInterface, index: number) => (
              <WarrantyInfo key={index} data={data} />
            ))}
        </DesktopContainer>
      </div>
      {warrantyTerms?.length !== 0 && (
        <div className="w-full md:basis-1/2">
          <DesktopContainer title={'Warranty terms'}>
            {warrantyTerms.map((data: InfoInterface, index: number) => (
              <WarrantyInfo key={index} data={data} />
            ))}
          </DesktopContainer>
        </div>
      )}
      <div className="pt-8 lg:px-8">
        {warrantyInfo
          .filter((data: InfoInterface) => data.title === 'Consumer Guarantee')
          .map((data: InfoInterface, index: number) => (
            <WarrantyInfo key={index} data={data} />
          ))}
      </div>
    </div>
  );
}

export default HoldingSingleWarrantyInfoView;
