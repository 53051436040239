import { Button, ButtonSocial } from '@rabbit/elements/shared-components';
import catLogin from '../../assets/images/cat_login.png';
import ROUTE_NAME from '../../utils/url-constants';
import { useTranslation } from 'react-i18next';

export function AuthStart(props: any) {
  const { onChange } = props;
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-4">
      <img
        src={catLogin}
        alt={'catlogin'}
        className="mx-auto max-h-[200px] max-w-[180px] lg:max-h-[270px] lg:max-w-[250px]"
      ></img>
      <div className="mb-7">
        <p className="font-nunito text-center text-lg">
          {
            //{t("auth_strapline", { defaultValue: '' })} {' '}}
          }
          A new way to manage and care for your products and our planet{' '}
          <span role="img" aria-label="plant emoji">
            🌱
          </span>
        </p>
      </div>
      <Button className="mb-3" kind="primary" onClick={() => onChange(2)}>
        Continue with email
      </Button>
      <div className="mb-3">
        <ButtonSocial type="apple" label="Sign in with Apple" />
      </div>
      <ButtonSocial type="google" label="Sign in with Google" />
      <p className="font-nunito mt-3 text-center text-sm font-medium">
        By continuing, you agree to iWarranty’s{' '}
        <a
          href={ROUTE_NAME.TERMS_AND_CONDITIONS}
          target="_blank"
          rel="noreferrer"
        >
          Terms and Conditions
        </a>{' '}
        and{' '}
        <a href={ROUTE_NAME.PRIVACY_POLICY} target="_blank" rel="noreferrer">
          Privacy Policy
        </a>
      </p>
      <p className="font-nunito mt-3 text-center text-xs font-medium">
        All RICHARD SCARRY® characters and trademarks, including BUSYTOWN™,
        LOWLY WORM™, HUCKLE CAT™ and the APPLECAR DESIGN™, are the intellectual
        property of The Richard Scarry Corporation AG. © 2021
      </p>
    </div>
  );
}

export default AuthStart;
