import { useContext, useEffect, useState } from 'react';
import { useNavigate, useLocation, Navigate } from 'react-router-dom';
import { Formik, Form } from 'formik';
import {
  LIST_COUNTRIES,
  useConsumerHoldingEditor,
  useGetConsumerHoldings,
} from '@rabbit/bizproc/react';
import {
  Button,
  DesktopContainer,
  Input,
  LoadingSpinner,
} from '@rabbit/elements/shared-components';
import ROUTE_NAME from 'apps/olive/src/utils/url-constants';
import { fromUnixTime } from 'date-fns';
import { UserContext } from 'apps/olive/src/context/UserContext';

//@ts-ignore
const isDemoEnv = import.meta.env.VITE_DEMO;

/* eslint-disable-next-line */
export interface HoldingSingleEditViewProps {}

interface FormValuesShape {
  serial: string;
  purchase_location: {
    country: string;
    docid: string;
  } | null;
  purchase_proof: File;
  purchase_time: number;
  store_not_listed: boolean;
  custom_store_name: string;
  purchase_country: string;
}

export function HoldingSingleEditView(props: HoldingSingleEditViewProps) {
  const location = useLocation();
  const navigate = useNavigate();
  const { consumerPersonaId } = useContext(UserContext) || {};
  const { getSingleHolding } = useGetConsumerHoldings(consumerPersonaId || '');
  const holdingId = location.pathname.split('/')[2];
  const { body, isReady, update, commit } = useConsumerHoldingEditor(
    consumerPersonaId ?? '',
    holdingId
  );
  const [readyToEdit, setReadyToEdit] = useState(isReady);
  const [, setSelectedValue] = useState<File | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [storeLabel, setStoreLabel] = useState<string>(
    'Enter store name & location'
  );

  const {
    public: holding_public,
    manufacturer: holding_manufacturer,
    private: holding_private,
  } = body;

  const [initialValues, setInitialValues] = useState<FormValuesShape>({
    serial: '',
    purchase_location: null,
    purchase_proof: {} as File,
    purchase_time: 0,
    store_not_listed: holding_private?.purchase_location_other ? true : false,
    custom_store_name: holding_private?.purchase_location_other ?? '',
    purchase_country: holding_private?.purchase_country ?? '',
  });

  useEffect(() => {
    void (async () => {
      const holding = await getSingleHolding(holdingId);
      if (holding instanceof Error) console.log('holding', holding);
      else {
        setInitialValues({
          serial: holding.serial || '',
          purchase_location: null,
          purchase_proof: {} as File,
          purchase_time: holding?.purchase_time ?? 0,
          store_not_listed: holding?.purchase_location_other ? true : false,
          custom_store_name: holding?.purchase_location_other ?? '',
          purchase_country: holding?.purchase_country ?? '',
        });
        holding?.full_purchase_location &&
          setStoreLabel(
            `${holding?.full_purchase_location?.name} (${holding?.full_purchase_location?.address?.town} - ${holding?.full_purchase_location?.address?.county})`
          );
        setLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    if (isReady && body) {
      setReadyToEdit(true);
      // setInitialValues({
      //   serial: body.public?.serial || '',
      //   // purchase_location: body.private?.purchase_location || '',
      //   purchase_proof: {} as File,
      //   purchase_time: fromUnixTime(date as number) || 0,
      // });
    }
  }, [isReady, body]);

  const handleUpdate = async (values: FormValuesShape) => {
    // Since the body of the holding already exists, we can trust data to not be undefined

    // If values include a retailer from the database, then purchase_location_other should be cleared - and vice versa
    const newPurchaseLocation =
      values.custom_store_name && values.store_not_listed
        ? ''
        : values.purchase_location?.docid ??
          holding_private?.purchase_location ??
          '';

    const newPurchaseCountry =
      values.custom_store_name && values.store_not_listed
        ? values.purchase_country
        : values.purchase_location?.country ??
          holding_private?.purchase_country ??
          '';

    const newPurchaseLocationOther = values.store_not_listed
      ? values.custom_store_name ??
        holding_private?.purchase_location_other ??
        ''
      : null;

    const {
      public: prevPublic,
      private: prevPrivate,
      manufacturer: prevManufacturer,
    } = body;

    try {
      update({
        public: {
          ...prevPublic!,
          serial: values.serial ? values.serial : prevPublic?.serial,
          // todo make function to get purchase time, this is messy - dc
          purchase_time: values.purchase_time
            ? Math.floor(values.purchase_time)
            : prevManufacturer?.purchase_time
            ? prevManufacturer?.purchase_time
            : 0,
        },
        private: {
          ...prevPrivate!,
          purchase_location: newPurchaseLocation,
          purchase_location_other: newPurchaseLocationOther,
          purchase_country: newPurchaseCountry,
        },
        manufacturer: {
          ...prevManufacturer!,
          purchase_location: newPurchaseLocation,
          purchase_location_other: newPurchaseLocationOther,
          purchase_country: newPurchaseCountry,
          purchase_time: values.purchase_time
            ? Math.floor(values.purchase_time)
            : prevManufacturer?.purchase_time,
        },
      });
      await commit();
      setIsSubmitting(false);
      navigate(-1);
    } catch (err) {
      console.log(err);
      setIsSubmitting(false);
      throw new Error('Something went wrong while updating the holding');
    }
  };

  //TODO: CHECK SECOND UPDATE OF THE FILE, THROWING AN ERROR ON BROWSER CONSOLE
  const onSubmit = async (values: FormValuesShape) => {
    if (!readyToEdit) throw new Error('Not ready to edit');
    setIsSubmitting(true);
    void (await handleUpdate(values));
  };

  if (!holding_public || !holding_private || !holding_manufacturer)
    return <LoadingSpinner size="sm" isFullScreen />;

  if (holding_public.self_registration?.title) {
    const url = location.pathname.substring(
      0,
      location.pathname.lastIndexOf('/')
    );
    return <Navigate to={url + '/edit-info'} />;
  }

  return (
    <DesktopContainer
      title="Edit information"
      goBack={() => navigate(-1)}
      loading={!readyToEdit || isSubmitting}
    >
      <div className="flex flex-col">
        {isLoading ? (
          <LoadingSpinner size="xs" />
        ) : (
          <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            onSubmit={onSubmit}
          >
            {({ values, errors }) => {
              if (values.purchase_proof)
                setSelectedValue(values.purchase_proof);
              return (
                <Form className="font-nunito flex flex-col gap-5">
                  <div>
                    <Input
                      label="Purchase date"
                      type="datepicker"
                      name="purchase_time"
                      settings={{
                        placeholder: 'Purchase date',
                        maxDate: new Date(),
                        initialDate:
                          typeof values?.purchase_time == 'number'
                            ? fromUnixTime(values?.purchase_time)
                            : values?.purchase_time || new Date(),
                      }}
                    />
                  </div>
                  {isDemoEnv ? (
                    <div>
                      <Input
                        type="text"
                        label="Serial number"
                        name="serial"
                        settings={{
                          id: 'serial',
                          placeholder: "The product's serial number",
                          allowSpecialCharacter: false,
                        }}
                      />
                    </div>
                  ) : null}
                  <div>
                    <Input
                      type="autocomplete-location-retailer"
                      name="purchase_location"
                      label="Store"
                      settings={{
                        id: 'purchase_location',
                        placeholder: storeLabel,
                        isMulti: false,
                        disabled: values.store_not_listed,
                        options: [],
                      }}
                    />
                  </div>
                  <div>
                    <Input
                      type="checkbox"
                      name="store_not_listed"
                      settings={{
                        checkboxLabel: 'Store is not listed',
                        checkboxLabelStyles: 'text-base text-gray-500',
                      }}
                    />
                  </div>
                  {values.store_not_listed && (
                    <div className="flex flex-col gap-4">
                      <Input
                        type="text"
                        name="custom_store_name"
                        settings={{
                          id: 'custom_store_name',
                          placeholder: 'Enter store name',
                        }}
                      />
                      <Input
                        type="select"
                        label=""
                        name="purchase_country"
                        settings={{
                          options: LIST_COUNTRIES,
                          id: 'purchase_country',
                          placeholder:
                            'The country where the product was purchased',
                        }}
                      />
                    </div>
                  )}
                  <label className="mb-[-10px]">Proof of purchase</label>
                  <Button
                    kind={'primary'}
                    size={'md'}
                    className={'bg-primary-700 hover:bg-primary-800 text-white'}
                    onClick={() =>
                      navigate(
                        ROUTE_NAME.PRODUCTS + `/${holdingId}/proof-purchase`
                      )
                    }
                  >
                    View proof of purchase (
                    {body.private?.receipt?.length.toString() ?? '0'})
                  </Button>
                  <div className="mt-6 flex">
                    <Button
                      kind="primary"
                      type="submit"
                      loading={!readyToEdit || isSubmitting}
                      disabled={!readyToEdit && !!errors}
                    >
                      Save
                    </Button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        )}
      </div>
    </DesktopContainer>
  );
}

export default HoldingSingleEditView;
